import {
    SugorokuMaster,
    SugorokuStageEvent,
} from '../types/sugorokuMasterType';

/**
 * 現在の位置から次のステージイベントまでのマス数を計算する
 * @param currentCell 現在のマス目
 * @param sugorokuMaster すごろくマスターデータ
 * @returns 次のステージイベントまでのマス数
 */
export const calculateTilesToNextEvent = (
    currentCell: number,
    sugorokuMaster: SugorokuMaster | null
): number => {
    if (!sugorokuMaster?.sugorokuMap?.stages) {
        return 0;
    }

    let currentStageIndex = 0;
    let passedCells = 0;

    // 現在のステージを特定
    for (const stage of sugorokuMaster.sugorokuMap.stages) {
        const stageTotalCells = stage.totalCells || 0;

        if (passedCells + stageTotalCells > currentCell) {
            // 現在のステージ内での相対位置を計算
            const relativePosition = currentCell - passedCells;

            // このステージにイベントがある場合
            if (stage.events && stage.events.length > 0) {
                // このステージ内で次のイベントを探す
                for (const event of stage.events) {
                    if (event.cell > relativePosition) {
                        return event.cell - relativePosition;
                    }
                }
            }

            // このステージに次のイベントがない場合、次のステージのイベントまでの距離を計算
            let remainingCells = stageTotalCells - relativePosition;
            let nextStageIndex = currentStageIndex + 1;

            while (nextStageIndex < sugorokuMaster.sugorokuMap.stages.length) {
                const nextStage =
                    sugorokuMaster.sugorokuMap.stages[nextStageIndex];
                if (nextStage.events && nextStage.events.length > 0) {
                    return remainingCells + nextStage.events[0].cell;
                }
                remainingCells += nextStage.totalCells || 0;
                nextStageIndex++;
            }
            return 0;
        }

        passedCells += stageTotalCells;
        currentStageIndex++;
    }

    return 0;
};

/**
 * 現在の位置から指定したイベントマスまでのマス数を計算する
 * @param currentCell 現在のマス目
 * @param sugorokuMaster すごろくマスターデータ
 * @param fromLast 最後から何番目のイベントマスを対象とするか（1: 最後のイベント、2: 最後から2番目のイベント...）
 * @returns イベントマスまでのマス数
 */
export const calculateTilesToLastEvent = (
    currentCell: number,
    sugorokuMaster: SugorokuMaster | null,
    fromLast: number = 1
): number => {
    if (!sugorokuMaster?.sugorokuMap?.stages) {
        return 0;
    }

    // すべてのイベントを収集し、セル位置でソート
    const allEvents: { event: SugorokuStageEvent; absoluteCell: number }[] = [];
    let accumulatedCells = 0;

    for (const stage of sugorokuMaster.sugorokuMap.stages) {
        if (stage.events && stage.events.length > 0) {
            for (const event of stage.events) {
                allEvents.push({
                    event,
                    absoluteCell: accumulatedCells + event.cell,
                });
            }
        }
        accumulatedCells += stage.totalCells || 0;
    }

    // イベントを絶対位置でソート
    allEvents.sort((a, b) => a.absoluteCell - b.absoluteCell);

    // 最後からfromLast番目のイベントを取得
    const targetEventIndex = allEvents.length - fromLast;
    if (targetEventIndex < 0 || targetEventIndex >= allEvents.length) {
        return 0;
    }

    const targetEvent = allEvents[targetEventIndex];

    // 現在位置が対象イベントを超えている場合は0を返す
    if (currentCell >= targetEvent.absoluteCell) {
        return 0;
    }

    // 現在位置から対象イベントまでの距離を計算
    return targetEvent.absoluteCell - currentCell;
};
