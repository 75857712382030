import React from 'react';
import styled from 'styled-components';
import Dialog from './Dialog';
import { isAndroid, isIOS } from 'react-device-detect';
import appIcon from '../../public/conft225_android_round.png';
import iosDownload from '../assets/images/ios-download.png';
import androidDownload from '../assets/images/android-download.png';
import pcDownload from '../assets/images/pc-download.png';

const DialogImageWrapper = styled.div`
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
`;

const PwaMessageWrapper = styled.div`
    font-weight: bold;
    text-align: center;
`;

const AppIconImage = styled.img`
    width: 40%;
    height: auto;
`;

const IOSDownloadImage = styled.img`
    width: 10%;
    height: auto;
`;

const AndroidDownloadImage = styled.img`
    width: 7%;
    height: auto;
    margin-bottom: 5px;
`;

const PCDownloadImage = styled.img`
    width: 10%;
    height: auto;
`;

const PwaAnnotationMessageWrapper = styled.div`
    margin-top: 40px;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
`;

const PwaAnnotationMessageTitle = styled.span`
    font-weight: bold;
`;

interface PwaInstallDialogProps {
    isOpen: boolean;
    closeModal: () => void;
    dialogRef: React.RefObject<HTMLDialogElement>;
}

/**
 * PWAインストール方法を説明するダイアログコンポーネント
 */
const PwaInstallDialog: React.FC<PwaInstallDialogProps> = ({
    isOpen,
    closeModal,
    dialogRef,
}) => {
    return (
        <Dialog
            ref={dialogRef}
            isOpen={isOpen}
            DialogImageWrapper={<DialogImageWrapper />}
            closeModal={closeModal}
            dialogContentPadding="0px 20px"
            $color="#ffffff"
            $backgroundColor="#000000"
            $borderColor="#000000"
            $backgroundAlphaPercent={100}
        >
            <PwaMessageWrapper>
                <AppIconImage src={appIcon} alt="アプリアイコン" />
                <br />
                ホーム画面に追加すると
                <br />
                次回からスムーズにアクセスできます
                <br />
                <br />
                {isIOS ? (
                    <>
                        <IOSDownloadImage
                            src={iosDownload}
                            alt="PWAインストール"
                        />
                        <br />
                        ブラウザのアドレスバーからこのアイコンをタップしてホームに追加
                    </>
                ) : isAndroid ? (
                    <>
                        <AndroidDownloadImage
                            src={androidDownload}
                            alt="PWAインストール"
                        />
                        <br />
                        ブラウザのアドレスバーからこのアイコンをタップしてホームに追加
                        ＞ インストール
                    </>
                ) : (
                    <>
                        <PCDownloadImage
                            src={pcDownload}
                            alt="PWAインストール"
                        />
                        <br />
                        ブラウザのアドレスバーからこのアイコンをタップしてホームに追加
                    </>
                )}
            </PwaMessageWrapper>
            <PwaAnnotationMessageWrapper>
                <PwaAnnotationMessageTitle>
                    ＜既にホーム画面にリンクを追加済みの方＞
                </PwaAnnotationMessageTitle>
                <br />
                お手数ですがホーム画面のリンクを削除した後、再度リンクの追加をお願いします
            </PwaAnnotationMessageWrapper>
        </Dialog>
    );
};

export default PwaInstallDialog;
