import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

export type Season = {
    id: number;
    name: string;
};

const Container = styled.div`
    max-width: 400px;
    padding: 0 20px;
    margin: 0 auto;
`;

const SelectContainer = styled.div`
    position: relative;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: flex-end;
    margin: 16px 10px 6px;
`;

const SelectButton = styled.button`
    position: relative;
    padding: 4px 24px 4px 8px;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    text-align: left;
    appearance: none;
    cursor: pointer;
    background: none;
    border: none;

    &::before {
        position: absolute;
        inset: -8px 0 -4px;
        content: '';
    }

    &:focus {
        outline: none;
    }
`;

const Arrow = styled.span<{ $isOpen: boolean }>`
    margin-left: -20px;
    pointer-events: none;
    border-color: ${({ $isOpen }) =>
        $isOpen
            ? 'transparent transparent #333333'
            : '#333333 transparent transparent'};
    border-style: solid;
    border-width: ${({ $isOpen }) => ($isOpen ? '0 4px 6px' : '6px 4px 0')};
`;

const DropdownList = styled.ul<{ $isOpen: boolean }>`
    position: absolute;
    top: 100%;
    right: -8px;
    z-index: 1000;
    display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
    min-width: 40px;
    padding: 2px 0;
    margin: 4px 0 0;
    list-style: none;
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
`;

const DropdownItem = styled.li<{ $isSelected: boolean }>`
    padding: 8px 16px;
    font-size: 14px;
    font-weight: ${({ $isSelected }) => ($isSelected ? 'bold' : 'normal')};
    color: #333333;
    cursor: pointer;
    background: ${({ $isSelected }) =>
        $isSelected ? '#f0f0f0' : 'transparent'};

    &:hover {
        background: ${({ $isSelected }) =>
            $isSelected ? '#f0f0f0' : '#f5f5f5'};
    }
`;

type Props = {
    seasons: Season[];
    selectedSeason: number;
    onSeasonChange: (seasonId: number) => void;
};

export const SeasonSelector: React.FC<Props> = ({
    seasons,
    selectedSeason,
    onSeasonChange,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const selectedSeasonName = seasons.find(
        (season) => season.id === selectedSeason
    )?.name;

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsOpen(false);
        }
    };

    return (
        <Container>
            <SelectContainer ref={containerRef}>
                <SelectButton
                    onClick={() => setIsOpen(!isOpen)}
                    onKeyDown={handleKeyDown}
                    aria-haspopup="listbox"
                    aria-expanded={isOpen}
                >
                    {selectedSeasonName}
                </SelectButton>
                <Arrow $isOpen={isOpen} />
                <DropdownList
                    $isOpen={isOpen}
                    role="listbox"
                    aria-label="シーズン選択"
                >
                    {seasons.map((season) => (
                        <DropdownItem
                            key={season.id}
                            $isSelected={season.id === selectedSeason}
                            onClick={() => {
                                onSeasonChange(season.id);
                                setTimeout(() => {
                                    setIsOpen(false);
                                }, 100);
                            }}
                            role="option"
                            aria-selected={season.id === selectedSeason}
                        >
                            {season.name}
                        </DropdownItem>
                    ))}
                </DropdownList>
            </SelectContainer>
        </Container>
    );
};
