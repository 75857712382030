import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QUERY_PARAM_KEY, QUERY_PARAM_VALUE } from '../constants/tabParams';
import { useDialog } from '../components/UseDialog';

/**
 * PWAインストールダイアログの表示状態を管理するカスタムフック
 *
 * @param showPwaDialogProp - 外部から指定されたダイアログ表示フラグ（省略可）
 * @returns ダイアログ関連の状態と関数
 */
export const usePwaInstallDialog = (showPwaDialogProp = false) => {
    const [searchParams] = useSearchParams();
    const [showPwaDialog, setShowPwaDialog] = useState(false);
    const { ref, closeModal } = useDialog();

    // クエリパラメータとpropsからPWAダイアログ表示フラグを取得
    useEffect(() => {
        const showPwaDialogParam = searchParams.get(
            QUERY_PARAM_KEY.SHOW_PWA_DIALOG
        );

        if (
            showPwaDialogParam === QUERY_PARAM_VALUE.TRUE ||
            showPwaDialogProp
        ) {
            setShowPwaDialog(true);
        }
    }, [searchParams, showPwaDialogProp]);

    // PWAインストールダイアログを表示する
    const handleShowPwaDialog = () => {
        setShowPwaDialog(true);
    };

    // PWAインストールダイアログを閉じる
    const handleClosePwaDialog = () => {
        setShowPwaDialog(false);
        closeModal();
    };

    return {
        showPwaDialog,
        handleShowPwaDialog,
        handleClosePwaDialog,
        dialogRef: ref,
    };
};
