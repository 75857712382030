import React, { useState, useEffect, useCallback } from 'react';
import { isPWAEnabled } from '../lib/isPWAEnabled';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import iconExternalLink from '../assets/images/icon-externallink.svg';
import { ButtonLink } from '../components/button';
import SessionStorageManager from '../lib/sessionStorageManager';

import background from '../assets/images/background.jpg';
import { AccountInfoLoaderDataType } from '../types/accountInfoLoaderData';
import { Await, useLoaderData, useSearchParams } from 'react-router-dom';
import AccountInfoType from '../types/accountInfo';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import Dialog from '../components/Dialog';
import { useDialog } from '../components/UseDialog';
import dolewakuwakuImage from '../assets/images/dolekun-tilting.svg';
import CookieManager from '../lib/cookieManager';
import { COOKIE_KEYS } from '../constants/cookieKeys';
import PrivateKeySection from '../components/PrivateKeySection';
import { resolveImagePath } from '../utils/imagePathResolver';
import { TAB_PARAM, QUERY_PARAM_KEY } from '../constants/tabParams';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100dvh - 82px - 48px);
    background-image: ${`url("${background}")`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    padding: 0 20px 20px;
    font-size: 14px;
`;

const InfoTitle = styled.div`
    width: 100%;
    margin-top: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #141313;
    text-align: center;
`;

const IconContent = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
`;

type CircleIconProps = {
    itemProp?: string;
};

const CircleIcon = styled.button<CircleIconProps>`
    width: 70px;
    height: 70px;
    background: url(${(props) => resolveImagePath(props.itemProp)}) no-repeat
        center/cover;
    background-color: white;
    border: none;
    border-radius: 50%;
`;

const InfoContentPlaceholder = styled.div`
    display: flex;
    align-items: center;
    height: 240px;
    font-weight: bold;
`;

const InfoContent = styled.div`
    width: 100%;
    margin-top: 20px;
    font-weight: bold;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid #ffffff;

    \&:last-of-type {
        border-bottom: 1px solid #ffffff;
    }
`;

const InfoLabel = styled.span`
    flex: 3;
`;

const InfoValue = styled.span`
    flex: 6;
    line-height: 20px;
    word-break: break-all;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    margin-top: 20px;

    & > a {
        display: inline-block;
    }
`;

// 外部リンクへ遷移する画像のスタイル
const LinkImage = styled.img`
    width: 16px;
    height: 12px;
    margin: 0 3px;

    /* #141313 に相当するfilter */
    filter: brightness(0) saturate(100%) invert(5%) sepia(4%) saturate(486%)
        hue-rotate(314deg) brightness(100%) contrast(95%);
`;

const TabContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 31px;
    color: #7d7d7d;
`;

const TabWrap = styled.div`
    flex: 1;
    width: 150px;
    border-radius: 5px;

    @media (display-mode: standalone) {
        width: 105px;
    }
`;

const Separator = styled.div`
    margin: 0 10px;
    font-size: 18px;
    color: #aaaaaa;

    @media (display-mode: standalone) {
        margin: 0 5px;
    }
`;

type TabProps = {
    disabled?: boolean;
};

const Tab = styled.div<TabProps>`
    padding: 3px;
    font-weight: ${(props) => (props.disabled ? 'bold' : 'normal')};
    color: ${(props) => (props.disabled ? 'black' : '#7D7D7D')};
    text-align: center;
    cursor: pointer;

    &::after {
        display: ${(props) => (props.disabled ? 'block' : 'none')};
        height: 3px;
        margin: auto;
        content: '';
    }
`;

const ToggleWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ToggleLabel = styled.span`
    margin-left: 10px;
`;

const ToggleSwitch = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    & input {
        width: 0;
        height: 0;
        opacity: 0;
    }

    & .slider {
        position: absolute;
        inset: 0;
        cursor: pointer;
        background-color: #cccccc;
        transition: 0.4s;

        &::before {
            position: absolute;
            bottom: 4px;
            left: 4px;
            width: 26px;
            height: 26px;
            content: '';
            background-color: white;
            transition: 0.4s;
        }
    }

    & input:checked + .slider {
        background-color: #4caf50;
    }

    & input:focus + .slider {
        box-shadow: 0 0 1px #4caf50;
    }

    & input:checked + .slider::before {
        transform: translateX(26px);
    }

    & .slider.round {
        border-radius: 34px;

        &::before {
            border-radius: 50%;
        }
    }
`;

const MessageWrapper = styled.div`
    font-size: 14px;
    font-weight: bold;
    text-align: center;
`;

const MessageTitle = styled.span`
    font-size: 16px;
`;

const Message = styled.div`
    margin-top: 15px;
`;

const AnnotationMessage = styled.div`
    margin-top: 20px;
    font-size: 12px;
`;

const DialogImageWrapper = styled.div`
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImage = styled.img`
    width: 70px;
`;

type BadgeProps = {
    disabled: boolean;
};

const PushNotificationStatusBadge = styled.span<BadgeProps>`
    padding: 2px 5px;
    margin: 0 5px;
    color: #ffffff;
    background-color: ${(props) => (props.disabled ? '#4caf50' : '#e40513')};
    border-radius: 5px;
`;

const AccountInfo: React.FC = () => {
    const data = useLoaderData() as AccountInfoLoaderDataType;
    const account_info = data.account_info;
    const { ref, closeModal } = useDialog();
    const [searchParams] = useSearchParams();

    const TAB_INDEX_STATE = {
        JLEAGUE: 0,
        WALLET: 1,
        NOTIFICATION: 2,
    };

    // クエリパラメータからタブを取得
    const getInitialTabIndexFromQuery = useCallback(() => {
        const tabParam = searchParams.get(QUERY_PARAM_KEY.TAB);
        if (tabParam === TAB_PARAM.JLEAGUE) return TAB_INDEX_STATE.JLEAGUE;
        if (tabParam === TAB_PARAM.WALLET) return TAB_INDEX_STATE.WALLET;
        if (tabParam === TAB_PARAM.NOTIFICATION && isPWAEnabled())
            return TAB_INDEX_STATE.NOTIFICATION;

        // クエリパラメータがない場合はセッションストレージから取得
        const storedTabIndex =
            SessionStorageManager.getSessionStorageValue('lastTabIndex');
        return storedTabIndex
            ? parseInt(storedTabIndex)
            : TAB_INDEX_STATE.JLEAGUE;
    }, [
        searchParams,
        TAB_INDEX_STATE.JLEAGUE,
        TAB_INDEX_STATE.WALLET,
        TAB_INDEX_STATE.NOTIFICATION,
    ]);

    // 初期タブインデックスを設定
    const [tabIndex, setTabIndex] = useState(getInitialTabIndexFromQuery());

    useEffect(() => {
        SessionStorageManager.setSessionStorageValue(
            'lastTabIndex',
            tabIndex.toString()
        );
    }, [tabIndex]);

    // クエリパラメータが変更された時にタブを更新
    useEffect(() => {
        const newTabIndex = getInitialTabIndexFromQuery();
        setTabIndex(newTabIndex);
    }, [searchParams, getInitialTabIndexFromQuery]);

    const JLEAGUE_CHANGE_USER_INFO_URL =
        import.meta.env.VITE_JLEAGUE_BASE_URL +
        import.meta.env.VITE_JLEAGUE_CHANGE_MEMBER_INFO_PATH;

    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized) {
            ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_ACCOUNT_INFO_PAGE);
        }
        setInitialized(true);
    }, [initialized]);

    const onClickJLeagueTab = () => {
        setTabIndex(TAB_INDEX_STATE.JLEAGUE);
    };

    const onClickWalletTab = () => {
        setTabIndex(TAB_INDEX_STATE.WALLET);
    };

    const onClickPushNotificationTab = () => {
        setTabIndex(TAB_INDEX_STATE.NOTIFICATION);
    };

    // 現在のプッシュ通知の設定を取得
    const [
        isNotificationPermissionGranted,
        setIsNotificationPermissionGranted,
    ] = useState(
        CookieManager.getCookieValue(
            COOKIE_KEYS.PUSH_NOTIFICATION_PERMISSION
        ) === 'granted'
    );

    // プッシュ通知のトグルを切り替えた際に表示するダイアログ
    const [isDialogOpen, setDialogOpen] = useState(false);

    /**
     * プッシュ通知のトグルを切り替えた際に表示するダイアログを開く
     */
    const handleToggleSwitch = () => {
        setIsNotificationPermissionGranted(!isNotificationPermissionGranted);
        // トグルの切り替えた感を出したいので少しだけ遅延させる
        setTimeout(() => {
            setDialogOpen(true);
        }, 200);
    };

    /**
     * ダイアログを閉じる
     */
    const handleDialogClose = () => {
        setIsNotificationPermissionGranted(!isNotificationPermissionGranted);
        setDialogOpen(false);
        closeModal();
    };

    return (
        <Wrapper>
            <Container>
                {isDialogOpen && (
                    <Dialog
                        ref={ref}
                        isOpen={true}
                        DialogImageWrapper={
                            <DialogImageWrapper>
                                <DialogImage
                                    src={dolewakuwakuImage}
                                    alt="dolekun"
                                />
                            </DialogImageWrapper>
                        }
                        closeModal={handleDialogClose}
                    >
                        <MessageWrapper>
                            <MessageTitle>
                                プッシュ通知を
                                {isNotificationPermissionGranted ? (
                                    <PushNotificationStatusBadge
                                        disabled={true}
                                    >
                                        有効
                                    </PushNotificationStatusBadge>
                                ) : (
                                    <PushNotificationStatusBadge
                                        disabled={false}
                                    >
                                        無効
                                    </PushNotificationStatusBadge>
                                )}
                                にする場合
                            </MessageTitle>
                            <br />
                            <Message>
                                お手数ですが、アプリの再インストールをお願いします。
                            </Message>
                            <AnnotationMessage>
                                ＜アプリ内データについて＞
                                <br />
                                アプリの再インストール後も同じJリーグIDでログインすると、元のデータは引き継がれます。
                            </AnnotationMessage>
                        </MessageWrapper>
                    </Dialog>
                )}
                <InfoTitle>編集</InfoTitle>
                <React.Suspense
                    fallback={
                        <IconContent>
                            <CircleIcon />
                        </IconContent>
                    }
                >
                    <Await resolve={account_info}>
                        {(accountInfo: AccountInfoType) => {
                            return (
                                <IconContent>
                                    <CircleIcon
                                        itemProp={accountInfo.icon_image_url}
                                    />
                                </IconContent>
                            );
                        }}
                    </Await>
                </React.Suspense>
                {/* タブ切り替え */}
                <TabContainer>
                    <TabWrap>
                        <Tab
                            onClick={onClickJLeagueTab}
                            disabled={tabIndex === TAB_INDEX_STATE.JLEAGUE}
                        >
                            JリーグID情報
                        </Tab>
                    </TabWrap>
                    <Separator>|</Separator>
                    <TabWrap>
                        <Tab
                            onClick={onClickWalletTab}
                            disabled={tabIndex === TAB_INDEX_STATE.WALLET}
                        >
                            ウォレット情報
                        </Tab>
                    </TabWrap>
                    {isPWAEnabled() && (
                        <>
                            <Separator>|</Separator>
                            <TabWrap>
                                <Tab
                                    onClick={onClickPushNotificationTab}
                                    disabled={
                                        tabIndex ===
                                        TAB_INDEX_STATE.NOTIFICATION
                                    }
                                >
                                    通知設定
                                </Tab>
                            </TabWrap>
                        </>
                    )}
                </TabContainer>
                {/* タブの内容 */}
                <React.Suspense
                    fallback={
                        <InfoContentPlaceholder>
                            Loading...
                        </InfoContentPlaceholder>
                    }
                >
                    <Await resolve={account_info}>
                        {(accountInfo: AccountInfoType) => {
                            if (tabIndex === TAB_INDEX_STATE.JLEAGUE) {
                                return (
                                    <>
                                        <InfoContent>
                                            <Row>
                                                <InfoLabel>JリーグID</InfoLabel>
                                                <InfoValue>
                                                    {accountInfo.attribute1}
                                                </InfoValue>
                                            </Row>
                                            <Row>
                                                <InfoLabel>生年月日</InfoLabel>
                                                <InfoValue>
                                                    {accountInfo.birthday}
                                                </InfoValue>
                                            </Row>
                                            <Row>
                                                <InfoLabel>氏名カナ</InfoLabel>
                                                <InfoValue>
                                                    {accountInfo.last_name}
                                                    {'　'}
                                                    {accountInfo.first_name}
                                                </InfoValue>
                                            </Row>
                                            <Row>
                                                <InfoLabel>性別</InfoLabel>
                                                <InfoValue>
                                                    {accountInfo.gender}
                                                </InfoValue>
                                            </Row>
                                            <Row>
                                                <InfoLabel>都道府県</InfoLabel>
                                                <InfoValue>
                                                    {accountInfo.prefecture}
                                                </InfoValue>
                                            </Row>
                                        </InfoContent>
                                        <ButtonWrapper>
                                            <ButtonLink
                                                to={
                                                    JLEAGUE_CHANGE_USER_INFO_URL
                                                }
                                                bgColor="#ffffff"
                                                textColor="#000000"
                                                borderColor="#000000"
                                                openInNewTab={
                                                    isPWAEnabled() && isMobile
                                                }
                                                onClick={() =>
                                                    ga4PushEvent(
                                                        GA4_CUSTOM_EVENT.PRESSED_ACCOUNT_INFO_BUTTON
                                                    )
                                                }
                                            >
                                                会員情報編集（JリーグID）
                                                <LinkImage
                                                    src={iconExternalLink}
                                                />
                                            </ButtonLink>
                                        </ButtonWrapper>
                                    </>
                                );
                            } else if (tabIndex === TAB_INDEX_STATE.WALLET) {
                                return (
                                    <>
                                        <InfoContent>
                                            <Row>
                                                <InfoLabel>アドレス</InfoLabel>
                                                <InfoValue>
                                                    {accountInfo.address}
                                                </InfoValue>
                                            </Row>
                                            <Row>
                                                <InfoLabel>
                                                    プライベートキー
                                                </InfoLabel>
                                                <InfoValue>
                                                    <PrivateKeySection />
                                                </InfoValue>
                                            </Row>
                                        </InfoContent>
                                    </>
                                );
                            } else {
                                return (
                                    <>
                                        <InfoContent>
                                            <Row>
                                                <InfoLabel>
                                                    プッシュ通知
                                                </InfoLabel>
                                                <InfoValue>
                                                    <ToggleWrapper>
                                                        <ToggleSwitch>
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    isNotificationPermissionGranted
                                                                }
                                                                onChange={
                                                                    handleToggleSwitch
                                                                }
                                                            />
                                                            <span className="slider round" />
                                                        </ToggleSwitch>
                                                        <ToggleLabel>
                                                            {isNotificationPermissionGranted
                                                                ? '有効'
                                                                : '無効'}
                                                        </ToggleLabel>
                                                    </ToggleWrapper>
                                                </InfoValue>
                                            </Row>
                                        </InfoContent>
                                    </>
                                );
                            }
                        }}
                    </Await>
                </React.Suspense>
            </Container>
        </Wrapper>
    );
};

export default AccountInfo;
