import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import { ClubPlayers, SugorokuMaster } from '../types/sugorokuMasterType';
import { getSugorokuMasterResponseBody } from './types/getSugorokuMasterType';
import { sugorokuMasterValidator } from './validators/sugorokuMasterValidator';
import { AxiosError, AxiosResponse } from 'axios';

/**
 * マスタデータ取得APIを呼び出して、LocalStorageにしまうところまで
 * @param code すごろくマップコード
 * @param version すごろくマップバージョン
 * @returns {SugorokuMaster} すごろくマスタ
 * @throws {Error} API呼び出しに失敗した場合にエラーを投げる
 */
export const getSugorokuMaster = async (
    code?: string,
    version?: string
): Promise<SugorokuMaster> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_SUGOROKU_MASTER,
            {
                params: {
                    code: code,
                    version: version,
                },
            }
        );
        const responseBody: getSugorokuMasterResponseBody = response.data ?? {};

        // とりあえずマップは絶対返ってくる
        const sugorokuMap = {
            code: responseBody.sugoroku_map.code,
            version: responseBody.sugoroku_map.version,
            title: responseBody.sugoroku_map.title,
            startDatetime: responseBody.sugoroku_map.start_datetime,
            endDatetime: responseBody.sugoroku_map.end_datetime,
            // 順番はサーバーで並び替え済なのでここでソートするとかはしない
            // localStorageの容量を少しでも削りたいので、いらないorderとpkは削る

            stages: responseBody.sugoroku_map.stages.map((stage) => {
                return {
                    sugorokuMapCode: stage.sugoroku_map_code,
                    code: stage.code,
                    areaCode: stage.area_code,
                    areaName: stage.area_name,
                    numberedAreaName: stage.numbered_area_name,
                    isFinal: stage.is_final,
                    totalCells: stage.total_cells,
                    events: stage.events.map((event) => {
                        return {
                            sugorokuStageCode: event.sugoroku_stage_code,
                            code: event.code,
                            title: event.title,
                            cell: event.cell,
                            itemCode: event.item_code,
                            item: event.item
                                ? {
                                      code: event.item.code,
                                      name: event.item.name,
                                      type: event.item.type,
                                      content: event.item.content,
                                      sugorokuMapCode:
                                          event.item.sugoroku_map_code,
                                      clubPlayerCode:
                                          event.item.club_player_code,
                                      clubPlayerName:
                                          event.item.club_player_name,
                                      clubPlayerPresentationName:
                                          event.item
                                              .club_player_presentation_name,
                                  }
                                : null,
                            pk: event.pk,
                        };
                    }),
                };
            }),
        };
        // 選手も返ってこないことがある。
        // API用に置換したものを配列に詰めていく
        let clubPlayers: ClubPlayers[] = [];
        if (responseBody.club_players) {
            clubPlayers = responseBody.club_players.map((player) => {
                return {
                    code: player.code,
                    name: player.name,
                    position: player.position,
                    hometown: player.hometown,
                    numberedHomeTown: player.numbered_hometown,
                    birthday: player.birthday,
                    height: player.height,
                    weight: player.weight,
                    iconImageUrl: player.icon_image_url,
                    areaCode: player.area_code,
                    areaName: player.area_name,
                    season: {
                        id: player.season.id,
                        name: player.season.name,
                    },
                    distributionMonth: player.distribution_month,
                };
            });
        }
        const sugorokuMaster = {
            sugorokuMap: sugorokuMap,
            clubPlayers: clubPlayers,
        } as SugorokuMaster;

        // バリデーション
        const isValid: boolean = sugorokuMasterValidator(sugorokuMaster);
        if (!isValid) {
            throw Error;
        }
        return sugorokuMaster;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 404) {
            // 404 エラーの場合は、適切なエラーメッセージを返す
            throw new Error('Sugoroku master data not found');
        } else {
            console.error('Error fetching sugoroku master:', error);
            throw error;
        }
    }
};
