import React from 'react';
import styled from 'styled-components';
import RankingHistoryButton from './RankingHistoryButton';

const RankingContainer = styled.div`
    position: relative;
    z-index: 4;
    height: 70px;
    background-color: white;
    border-radius: 0 45px 45px 0;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 16%);
`;

const RankingTitleContainer = styled.div`
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    width: 90px;
    height: 100%;
    padding-right: 12px;
    padding-left: 5px;
    background-color: #e40513;
    border-radius: 0 45px 45px 0;
`;

const RankingTitle = styled.span`
    font-size: 14px;
    font-weight: 900;
    line-height: 13px;
    color: white;
    letter-spacing: 0.42px;
`;

const RankingDetailsContainer = styled.div`
    position: relative;
    top: -56px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 70px;
    margin-left: 95px;
    font-weight: 900;
    color: black;
`;

const RankingText = styled.div`
    display: flex;
    align-items: center;
    margin: 0 16px;
    font-size: 12px;
    font-weight: 900;
`;

const RankingValue = styled.span`
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    color: #e40513;
    vertical-align: -1px;
`;

const LoadingDots = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    height: 18px;
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    color: #e40513;

    &::after {
        display: inline-block;
        width: 0;
        overflow: hidden;
        vertical-align: bottom;
        content: '...';
        animation: dots 1s steps(4, jump-none) infinite;
    }

    @keyframes dots {
        0% {
            width: 0;
        }

        20% {
            width: 0;
        }

        40% {
            width: 0.33em;
        }

        60% {
            width: 0.66em;
        }

        80%,
        100% {
            width: 1em;
        }
    }
`;

interface SugorokuRankingInfoProps {
    currentRank?: number;
    totalPlayers?: number;
    traversedCells?: number;
    pastRankings?: {
        sugoroku_map: string;
        rank: number | null;
        total_players: number;
    }[];
    seasonData?: {
        season_id: number;
        season_name: string;
        progress: {
            total_cells: number;
            rank: number;
            is_finished: boolean;
        };
    }[];
    currentTopRankings?: {
        rank: number;
        total_cell: number;
    }[];
    isSugorokuStarted?: boolean;
    isLoading?: boolean;
}

const SugorokuRankingInfo: React.FC<SugorokuRankingInfoProps> = ({
    currentRank,
    totalPlayers,
    traversedCells,
    pastRankings,
    seasonData,
    currentTopRankings,
    isSugorokuStarted = true,
    isLoading = false,
}) => {
    // データがundefinedまたはnullの場合もローディング中とみなす
    const isRankLoading =
        isLoading ||
        (isSugorokuStarted &&
            (currentRank === undefined || totalPlayers === undefined));
    const isCellsLoading =
        isLoading || (isSugorokuStarted && traversedCells === undefined);

    const fixedTraversedCells = !isSugorokuStarted
        ? '-'
        : traversedCells === undefined
          ? '-'
          : traversedCells === 0
            ? 0
            : traversedCells;

    const displayRank = !isSugorokuStarted ? '-' : currentRank || '-';
    const displayTotalPlayers = !isSugorokuStarted ? '-' : totalPlayers || '-';

    return (
        <RankingContainer>
            <RankingTitleContainer>
                <RankingTitle>あなたの順位</RankingTitle>
            </RankingTitleContainer>
            <RankingDetailsContainer>
                <RankingText>
                    今月：
                    {isRankLoading ? (
                        <>
                            <LoadingDots />位 / <LoadingDots />
                            人中
                        </>
                    ) : (
                        <>
                            <RankingValue>{displayRank}</RankingValue>位 /{' '}
                            {displayTotalPlayers}人中
                        </>
                    )}
                    <RankingHistoryButton
                        pastRankings={pastRankings}
                        seasonData={seasonData}
                        currentTopRankings={currentTopRankings}
                        traversedCells={traversedCells}
                    />
                </RankingText>
                <RankingText>
                    今月進んだマス数：
                    {isCellsLoading ? (
                        <LoadingDots />
                    ) : (
                        <RankingValue>{fixedTraversedCells}</RankingValue>
                    )}
                    マス
                </RankingText>
            </RankingDetailsContainer>
        </RankingContainer>
    );
};

export default SugorokuRankingInfo;
