import { getNfts } from '../api/getNfts';
import { defer } from 'react-router-dom';
import { EVENT_TYPE } from '../constants/eventType';

/**
 * ページ描画前に来場履歴を取得する
 *
 * @returns 来場履歴データを含むdeferオブジェクト
 */
export const nftLoader = () => {
    // Promiseを返却するように変更（awaitを使わない）
    const nftsPromise = (async () => {
        try {
            // 試合タイプとメモリアルタイプのNFTを取得
            const matchNftsPromise = getNfts(EVENT_TYPE.MATCH);
            const memorialNftsPromise = getNfts(EVENT_TYPE.MEMORIAL);

            // 両方のPromiseを並行して実行
            const [matchNfts, memorialNfts] = await Promise.all([
                matchNftsPromise,
                memorialNftsPromise,
            ]);

            // 結合したリストを作成
            const combinedNfts = [...matchNfts, ...memorialNfts];

            // イベント日付とイベントIDでソート
            const sortedNfts = combinedNfts.sort((a, b) => {
                // まずイベント日付で比較
                const dateA = new Date(a.event.date).getTime();
                const dateB = new Date(b.event.date).getTime();

                if (dateA !== dateB) {
                    return dateA - dateB; // 昇順（古い順）
                }

                // 日付が同じ場合はイベントIDで比較
                return a.event.id - b.event.id;
            });

            return sortedNfts;
        } catch (error) {
            console.error('NFTの取得中にエラーが発生しました:', error);
            // エラー時は空の配列を返す
            return [];
        }
    })();

    // deferを使用してPromiseをラップして返す
    return defer({ nfts: nftsPromise });
};
