// タブのクエリパラメータ値の定数
export const TAB_PARAM = {
    JLEAGUE: 'jleague',
    WALLET: 'wallet',
    NOTIFICATION: 'notification',
};

// クエリパラメータのキー
export const QUERY_PARAM_KEY = {
    TAB: 'tab',
    SHOW_PWA_DIALOG: 'showPwaDialog',
};

// クエリパラメータの値
export const QUERY_PARAM_VALUE = {
    TRUE: 'true',
    FALSE: 'false',
};
